@charset "utf-8";

@import "minimal-mistakes/skins/karmaniverous"; // skin
@import "minimal-mistakes"; // main partials

html {
  /* apply a natural box layout model to all elements */
  box-sizing: border-box;
  background-color: $background-color;
  font-size: $doc-font-size * 1px;

  @include breakpoint($medium) {
    font-size: $doc-font-size * 1.125px;
  }

  @include breakpoint($large) {
    font-size: $doc-font-size * 1.25px;
  }

  @include breakpoint($x-large) {
    font-size: $doc-font-size * 1.375px;
  }

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

figure.drop-image {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;

  @include breakpoint($small) {
    width: 33%;
  }
}

figure.drop-image + p::first-letter,
p.drop-cap::first-letter {
  @include breakpoint($small) {
    font-family: serif;
    initial-letter: 2;
    margin-right: 0.25em;
  }
}

table.gsc-above-wrapper-area-container {
  overflow: visible;
}
